import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin, FaInstagram, FaFacebook, FaYoutube, FaPhoneSquareAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();

  return (
    <footer className="bg-gray-900 text-muted-foreground py-8 px-4 sm:px-6 md:py-10 md:px-12 lg:py-14 lg:px-20">
      <div className="flex flex-col lg:flex-row gap-8 md:gap-12 lg:gap-20 text-white">
        <div className="flex flex-col mb-6 lg:mb-0">
          <h1 className="font-bold mb-4 text-lg sm:text-xl lg:text-2xl">GREEN LOBBY ENTERPRISES</h1>
          <p className="text-xs sm:text-sm lg:text-base">E-478 BASEMENT REAR SIDE, <br /> GREATER KAILASH- II</p>
          <p className="text-xs sm:text-sm lg:text-base">NEAR MTNL OFFICE, DELHI, South Delhi, Delhi</p>
          <p className="text-xs sm:text-sm lg:text-base">India, 110048</p>
        </div>

        <div className="flex flex-col mb-6 lg:mb-0">
          <h1 className="font-bold mb-4 text-lg sm:text-xl">Company</h1>
          <Link to="/" className="hover:text-primary mb-2 text-xs sm:text-sm lg:text-base">Home</Link>
          <Link to="/about" className="hover:text-primary mb-2 text-xs sm:text-sm lg:text-base">About</Link>
          <Link to="/careers" className="hover:text-primary text-xs sm:text-sm lg:text-base">Career</Link>
        </div>

        <div className="flex flex-col mb-6 lg:mb-0">
          <h1 className="font-bold mb-4 text-lg sm:text-xl">Help</h1>
          <Link to="/contact" className="hover:text-primary mb-2 text-xs sm:text-sm lg:text-base">Contact Us</Link>
          {/* <Link to="/privacypolicy" className="hover:text-primary text-xs sm:text-sm lg:text-base">Website Terms</Link> */}
        </div>

        <div className="flex flex-col mb-6 lg:mb-0">
          <h1 className="font-bold mb-4 text-lg sm:text-xl">Legal</h1>
          <Link to="/privacypolicy" className="hover:text-primary text-xs sm:text-sm lg:text-base">Privacy Policy</Link>
        </div>

        <div className="flex flex-col mb-6 lg:mb-0">
          <Link to="/login" className="hover:text-primary text-xs sm:text-sm lg:text-base">Login as vendor</Link>
        </div>

        <div className="flex flex-col">
          <h1 className="font-bold mb-4 text-lg sm:text-xl">Socials</h1>
          <div className="flex space-x-3 lg:space-x-4 mb-2">
            <a href="https://in.linkedin.com/company/procydia" className="hover:text-primary" aria-label="LinkedIn">
              <FaLinkedin size={20} />
            </a>
            <a href="https://www.instagram.com/procydia/" className="hover:text-primary" aria-label="Instagram">
              <FaInstagram size={20} />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100092634392706" className="hover:text-primary" aria-label="Facebook">
              <FaFacebook size={20} />
            </a>
            <a href="https://www.youtube.com/@proceedwiththeidea" className="hover:text-primary" aria-label="YouTube">
              <FaYoutube size={20} />
            </a>
          </div>
          <div className="text-xs sm:text-sm lg:text-base">
            <p className="font-semibold text-sm sm:text-lg lg:text-xl">Sanchit Gupta</p>
            <p>Founder</p>
            <div className="flex items-center gap-2 mt-2">
              <IoIosMail size={16} />
              <a href="mailto:connect@procydia.com" className="hover:underline">connect@procydia.com</a>
            </div>
            <div className="flex items-center gap-2 mt-1">
              <FaPhoneSquareAlt size={16} />
              <a href="tel:+919818347406" className="hover:underline">9818347406</a>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-700 my-6 lg:my-8"></div> {/* Horizontal line */}

      <div className="flex justify-center text-white">
        <p className="text-xs sm:text-sm lg:text-base text-center">
          GREEN LOBBY ENTERPRISES © {year}, All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
