// src/components/BlogList.jsx
import React from "react";
import { Link } from "react-router-dom";
import blogs from "../../blogs.json"; // Adjust the path as necessary
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";


const BlogList = () => {
  return (
    <div>
        <Navbar />
    <div className=" mx-auto p-8 m-20">
      <h1 className="text-4xl font-bold mb-8 text-center">Read Our Blogs to enhance your knowledge</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogs.map((blog) => (
          <div key={blog.id} className="bg-slate-100 shadow-lg rounded-lg overflow-hidden">
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-2">{blog.title}</h2>
              <p className="text-gray-600 font-semibold mb-4">{blog.excerpt}</p>
              <Link
  to={`/blogs/${blog.id}`}
  className="text-blue-800 hover:underline font-semibold"
>
  Read More
</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default BlogList;
