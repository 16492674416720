// src/components/BlogPost.jsx
import React from "react";
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import blogs from "../../blogs.json"; // Adjust the path as necessary
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const BlogPost = () => {
  const { id } = useParams();
  const blog = blogs.find((b) => b.id === id);

  if (!blog) {
    return (

        
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold">Blog Not Found</h2>
        {/* <Link to="/blogs" className="text-blue-500 hover:underline">
          Go back to blog list
        </Link> */}
      </div>
    );
  }

  return (
    <div>
        <Navbar />
    <div className="container mx-auto p-10 m-20 text-xl">
      {/* <Link to="/blogs" className="text-blue-500 hover:underline mb-4 inline-block">
        &larr; Back to Blogs
      </Link> */}
      <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
      <p className="text-gray-600 mb-8">{new Date(blog.date).toLocaleDateString()}</p>
      <ReactMarkdown className="prose">{blog.content}</ReactMarkdown>
    </div>
    <Footer />
    </div>
  );
};

export default BlogPost;
