import React from "react";
import { Link } from "react-router-dom";
import blogs from "../../blogs.json"; // Adjust the path as necessary
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const ConstructionBlogs = () => {
  // Filter blogs to only include those with the 'construction' category
  const constructionBlogs = blogs.filter((blog) => blog.category === "construction");

  return (
    <div>

      <div className="container mx-auto p-8 m-20 mt-2 mb-2">
        <h1 className="text-4xl font-bold mb-8 text-center">Read our blogs on Construction</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {constructionBlogs.map((blog) => (
            <div key={blog.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="p-6">
                <h2 className="text-2xl font-semibold mb-2">{blog.title}</h2>
                <p className="text-gray-600 mb-4">{blog.excerpt}</p>
                <Link
                  to={`/blogs/${blog.id}`}
                  className="text-blue-500 hover:underline font-semibold"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
        {/* View All Button */}
        <div className="flex justify-center mt-8">
          <Link
            to="/blogs" // Redirect to the main Blogs page
            className="bg-blue-500 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-600"
          >
            View All Blogs
          </Link>
        </div>
      </div>

    </div>
  );
};

export default ConstructionBlogs;
