import React, { useEffect, useRef, useState,useNavigate } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import { FaEdit } from "react-icons/fa";
import ProductComponent from "./Products";
import ServiceComponent from "../Home/services";
import ReviewsComponent from "./Reviews";
import ChatBubble from "./ChatBubble";
import { BsInstagram } from "react-icons/bs";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { IoEarth } from "react-icons/io5";
import { LuFolderEdit } from "react-icons/lu";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

const VendorDetails = ({  }) => {
  const [images, setImages] = useState([]);
  const { vendorId } = useParams();
  const [vendor, setVendor] = useState({
    companyName: "",
    ownerName: "",
    profilepicture: "",
    backgroundpicture: "",
    address: "",
    phone: "",
    instagram: "",
    facebook: "",
    linkedIn: "",
    email: "",
    webiste: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [uploadField, setUploadField] = useState(null); // Track which field (profile or background) to upload
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      try {
        const response = await axios.get(
          `https://procydia.onrender.com/api/vendor/${vendorId}`
        );
        setVendor(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVendorDetails();
  }, [vendorId]);

  // const handleImageChange = (e) => {
  //   setSelectedFile(e.target.files[0]);
  // };

  // const handleUpload = async () => {
  //   if (!selectedFile) return;

  //   const formData = new FormData();
  //   formData.append("profilepicture", selectedFile); // The name here should match the backend

  //   try {
  //     await axios.post(
  //       `https://procydia.onrender.com/api/vendor/upload/${vendorId}`,
  //       formData
  //     );
  //     // Refresh vendor data
  //     const response = await axios.get(
  //       `https://procydia.onrender.com/api/vendor/${vendorId}`
  //     );
  //     setVendor(response.data);
  //     setSelectedFile(null);
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   }
  // };

  // const triggerFileInput = (field) => {
  //   setUploadField(field); // Set the field before triggering file input
  //   fileInputRef.current.click();
  // };

  // useEffect(() => {
  //   if (selectedFile) {
  //     handleUpload();
  //   }
  // }, [selectedFile]);

  // const handleEditClick = (field) => {
  //   setEditingField(field);
  // };

  // const handleFieldChange = (field, value) => {
  //   setVendor((prevVendor) => ({
  //     ...prevVendor,
  //     [field]: value,
  //   }));
  // };

  // const handleFieldSubmit = async (field) => {
  //   try {
  //     const token = localStorage.getItem("authToken");

  //     if (!token) {
  //       throw new Error("No token found");
  //     }

  //     const response = await axios.put(
  //       `https://procydia.onrender.com/api/vendor/${vendorId}`,
  //       { [field]: vendor[field] },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     const updatedVendorResponse = await axios.get(
  //       `https://procydia.onrender.com/api/vendor/${vendorId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     setVendor(updatedVendorResponse.data);
  //     setEditingField(null);
  //   } catch (error) {
  //     console.error(
  //       "Error updating field:",
  //       error.response ? error.response.data : error.message
  //     );
  //     setError("Error updating field. Please try again.");
  //   }
  // };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Navbar />
      <div
  className="relative mt-16 w-full h-80 shadow-md bg-center bg-cover"
  style={{
    backgroundImage: `url(${
      vendor.backgroundpicture ||
      "https://img.freepik.com/free-vector/happy-tiny-people-near-huge-welcome-word-flat-illustration_74855-10808.jpg?t=st=1723893167~exp=1723896767~hmac=20b404de48d041ab40c66267f6eaa48ab707a5ac6dcd3614a46c65393ccf17c9&w=1060"
    })`,
    backgroundSize: 'contain',   // Ensures the full image is visible within the container
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents image repetition
  }}
>
</div>

      <div className="relative flex items-center pl-5 pb-9 text-black p-2 pt-0">
        <div className="relative ml-20 mr-6">
          <img
            src={vendor.profilepicture}
            className="pt-0 mt-0 transform -translate-y-1/4 w-72 h-72 rounded-full bg-white border-8 border-black shadow-lg"
          />
          {/* <LuFolderEdit
            className="absolute bottom-32 right-0 border-4 border-white text-white bg-gray-800 rounded-full p-1 w-12 h-12 cursor-pointer"
            onClick={triggerFileInput}
          /> */}
          {/* <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
          /> */}
        </div>
        <div className="p-5 pl-0">
          <div className="flex items-center">
            {editingField === "ownerName" ? (
              <>
                {/* <input
                  type="text"
                  value={vendor.ownerName}
                  onChange={(e) =>
                    handleFieldChange("ownerName", e.target.value)
                  }
                  className="bg-white text-black p-1"
                /> */}
                {/* <button
                  onClick={() => handleFieldSubmit("ownerName")}
                  className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Submit
                </button> */}
              </>
            ) : (
              <div className="flex items-center">
                <h2 className="text-3xl font-semibold">
                  {vendor.ownerName || "Owner Name"}
                </h2>
                {/* <FaEdit
                  className="ml-2 text-gray-600 cursor-pointer"
                  onClick={() => handleEditClick("ownerName")}
                /> */}
              </div>
            )}
          </div>
          <div className="flex items-center">
            {editingField === "companyName" ? (
              <>
                {/* <input
                  type="text"
                  value={vendor.companyName}
                  onChange={(e) =>
                    handleFieldChange("companyName", e.target.value)
                  }
                  className="bg-white text-black p-1"
                /> */}
                {/* <button
                  onClick={() => handleFieldSubmit("companyName")}
                  className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Submit
                </button> */}
              </>
            ) : (
              <div className="flex items-center">
                <p className="text-2xl font-extrabold">
                  {vendor.companyName || "Vendor Name"}
                </p>
                {/* <FaEdit
                  className="ml-2 text-gray-600 cursor-pointer"
                  onClick={() => handleEditClick("companyName")}
                /> */}
              </div>
            )}
          </div>
          <div className="flex items-center">
            {editingField === "address" ? (
              <>
                {/* <input
                  type="text"
                  value={vendor.address}
                  onChange={(e) => handleFieldChange("address", e.target.value)}
                  className="bg-white text-black p-1"
                /> */}
                {/* <button
                  onClick={() => handleFieldSubmit("address")}
                  className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Submit
                </button> */}
              </>
            ) : (
              <div className="flex items-center gap-2">
                <FaHome />
                <a href={`https://maps.google.com/?q=${vendor.address}`} className="text-lg font-normal">{vendor.address || "Address"}</a>
                {/* <FaEdit
                  className="ml-2 text-gray-600 cursor-pointer"
                  onClick={() => handleEditClick("address")}
                /> */}
              </div>
            )}
          </div>
          <div className="flex flex-auto gap-2">
            <FaPhoneVolume />
            <a href={`tel:${vendor.phone}`} className="text-sm font-semibold"> {vendor.phone || "Contact Number"}</a>
          </div>
          <div className="flex gap-2">
            <IoEarth className="" />
            <a href={`${vendor.website}`}>
              <p className="text-sm underline text-blue-800">
                {"website link"}
              </p>
            </a>
          </div>
          <button
            onClick={() =>
              (window.location.href = `mailto:${vendor.email}?subject=Get in Touch&body=Hello, I would like to get in touch with you.`)
            }
            className="bg-red-600 mt-2 p-2 rounded-xl text-xl text-white"
          >
            Get in touch
          </button>
        </div>
      </div>

      <div className="bg-gray-100 p-8 md:p-12">
  <h2 className="text-xl md:text-3xl font-semibold mb-6 text-gray-700">Connect with us on Socials</h2>

  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4"> {/* Adjusted grid layout and gap */}
  {vendor.instagram && (
    <div className="mb-4 max-w-xs mx-auto"> {/* Reduced width with max-w-xs */}
      <a
        href={vendor.instagram}
        className="flex items-center text-lg sm:text-xl text-white bg-pink-500 py-2 px-4 hover:bg-pink-400 "
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsInstagram className="mr-2" />
        Instagram
      </a>
      <div className="grid grid-cols-2"> {/* 2 columns with some gap */}
        {/* Sample square images for Instagram */}
        <a href={`${vendor.instagram}`}><img className="w-full h-36 object-cover  transition-all duration-300 ease-in-out transform hover:scale-105" src="/p-1.png" alt="Sample 1" /></a> {/* Reduced height */}
        <a href={`${vendor.instagram}`}><img className="w-full h-36 object-cover  transition-all duration-300 ease-in-out transform hover:scale-105" src="/p-2.png" alt="Sample 2" /></a> {/* Reduced height */}
        <a href={`${vendor.instagram}`}><img className="w-full h-36 object-cover  transition-all duration-300 ease-in-out transform hover:scale-105" src="/p-3.png" alt="Sample 3" /></a> {/* Reduced height */}
        <a href={`${vendor.instagram}`}><img className="w-full h-36 object-cover  transition-all duration-300 ease-in-out transform hover:scale-105" src="/p-4.png" alt="Sample 4" /></a> {/* Reduced height */}
      </div>
    </div>
  )}

  {/* Check if Facebook link exists */}
  {vendor.facebook && (
    <div className="mb-4 max-w-xs mx-auto"> {/* Reduced width with max-w-xs */}
      <a
        href={vendor.facebook}
        className="flex items-center text-lg sm:text-xl text-white bg-blue-600 py-2 px-4 hover:bg-blue-500"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaSquareFacebook className="mr-2" />
        Facebook
      </a>
      <div className="grid grid-cols-2"> {/* 2 columns with some gap */}
        {/* Sample square images for Facebook */}
        <a href={`${vendor.facebook}`}><img className="w-full h-36 object-cover transition-all duration-300 ease-in-out transform hover:scale-105" src="/gcic1.png" alt="Sample 1" /></a> {/* Reduced height */}
        <a href={`${vendor.facebook}`}><img className="w-full h-36 object-cover transition-all duration-300 ease-in-out transform hover:scale-105" src="/gcic2.png" alt="Sample 2" /></a> {/* Reduced height */}
        <a href={`${vendor.facebook}`}><img className="w-full h-36 object-cover transition-all duration-300 ease-in-out transform hover:scale-105" src="/gcic3.png" alt="Sample 3" /></a> {/* Reduced height */}
        <a href={`${vendor.facebook}`}><img className="w-full h-36 object-cover transition-all duration-300 ease-in-out transform hover:scale-105" src="/gcic4.png" alt="Sample 4" /></a> {/* Reduced height */}
      </div>
    </div>
  )}

  {vendor.linkedIn && (
    <a
      href={vendor.linkedIn}
      className="flex items-center justify-center text-lg sm:text-xl text-white bg-blue-500 rounded-lg py-2 px-4 hover:bg-blue-400 transition-all duration-300 ease-in-out transform hover:scale-105"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaLinkedin className="mr-2" />
      LinkedIn
    </a>
  )}
</div>

</div>
<div className="flex-grow p-8 md:p-12">
      <h2 className="text-3xl font-semibold text-gray-800 mb-6">
        Gallery
      </h2>

      {images && images.length > 0 ? (
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
          stopOnHover={true}
          showStatus={false}
          className="max-w-4xl mx-auto"
        >
          {images.map((image, index) => (
            <div key={index} className="w-full h-96">
              <img
                src={image}
                alt={`Gallery Image ${index + 1}`}
                className="w-full h-full object-cover rounded-lg shadow-md"
              />
            </div>
          ))}
        </Carousel>
      ) : (
        <p className="text-lg text-center text-gray-500">
          No images to show right now.
        </p>
      )}
    </div>
      {/* <div className="p-20 pt-6 bg-red-100">
        <h2 className="text-2xl font-semibold mb-4">
          Top Products and Services By vendor
        </h2>
        <a
          href="/all-events"
          className="text-black font-semibold hover:underline"
        >
          View All
        </a>
        <ProductComponent />
        <ServiceComponent />
      </div> */}

      {/* <div className="p-20 pt-10 bg-slate-100">
        <h2 className="text-2xl font-semibold mb-4 mt-0">Reviews</h2>
        <ReviewsComponent />
      </div> */}

      {/* <div className="p-20 pt-10 bg-slate-100 pr-96">
        <h2 className="text-2xl font-semibold mb-8">Notice Board</h2>
        <ChatBubble />
      </div> */}

      <Footer />
    </div>
  );
};

export default VendorDetails;
