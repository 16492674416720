import React from "react";
import { Link } from "react-router-dom";
import blogs from "../../blogs.json"; // Adjust the path as necessary

// Helper function to shuffle an array and return a subset
const getRandomBlogs = (blogs, count) => {
  const shuffledBlogs = [...blogs].sort(() => 0.5 - Math.random()); // Shuffle the array
  return shuffledBlogs.slice(0, count); // Select the number of random blogs
};

const HomeBlogs = () => {
  // Select a random subset of blogs (e.g., 3 random blogs)
  const randomBlogs = getRandomBlogs(blogs, 3); // You can adjust the number of random blogs

  return (
    <div>

      <div className=" mx-auto p-10 w-full mt-0">
        <h1 className="text-3xl font-bold mb-8 text-center">Explore Our Blogs 🧭</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {randomBlogs.map((blog) => (
            <div key={blog.id} className="bg-slate-100 shadow-lg rounded-lg overflow-hidden">
              <div className="p-6">
                <h2 className="text-2xl font-bold mb-2">{blog.title}</h2>
                <p className="text-gray-600 font-semibold mb-4">{blog.excerpt}</p>
                <Link
  to={`/blogs/${blog.id}`}
  className="text-blue-800 hover:underline font-semibold"
>
  Read More
</Link>

              </div>
            </div>
          ))}
        </div>
        {/* View All Button */}
        <div className="flex justify-center mt-8">
  <Link
    to="/blogs" // Redirect to the main Blogs page
    className="bg-blue-700 text-white px-5 py-2 rounded-lg font-semibold hover:bg-blue-800"
  >
    View All
  </Link>
</div>
      </div>

    </div>
  );
};

export default HomeBlogs;
